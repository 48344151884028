import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: 1px solid #a9a9a9;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
  background-color: #ffffff;

  &:has(input[type="radio"]:checked) {
    border: 1px solid ${({ theme }) => theme.colors.green};
    background-color: transparent;
  }

  &:has(input[type="radio"]:checked) > label {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const RadioCustom = styled.div`
  position: relative;
  display: flex;
  width: 18px;
  height: 18px;
  border: 2px solid #a9a9a9;
  border-radius: 9999px;

  &:has(input[type="radio"]:checked) {
    border: 2px solid ${({ theme }) => theme.colors.green};
  }

  &::after {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 9999px;
    border: 2px solid #a9a9a9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: none;
  }

  &:has(input[type="radio"]:checked):after {
    border: 2px solid ${({ theme }) => theme.colors.green};
    background-color: ${({ theme }) => theme.colors.green};
  }
`;

export const Radio = styled.input`
  display: none;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #333;
  background-color: #fff;
  transition: background-color 0.2s, border-color 0.2s;
  background-color: #333;
  border-color: #333;
`;

export const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
`;
