/** @jsxImportSource @emotion/react */
import React, { Children, FC, cloneElement } from "react";
import * as AccordionItemStyles from "./AccordionItem.styles";
import { BoxProps } from "src/components/UI/Box";
import { useAccordionContext } from "../Accordion.context";

export type AccordionItemProps = React.ComponentPropsWithoutRef<"div"> &
  BoxProps & {
    index?: number;
    children: React.ReactElement<any>[];
  };

const AccordionItem: FC<AccordionItemProps> = (props) => {
  const { children, index } = props;
  const { activeIndex } = useAccordionContext();

  const clonedChildren = Children.map(children, (child) =>
    cloneElement(child, { activeIndex, index })
  );

  return (
    <AccordionItemStyles.Container>
      {clonedChildren}
    </AccordionItemStyles.Container>
  );
};

export default AccordionItem;
