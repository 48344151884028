/** @jsxImportSource @emotion/react */
import React, { Children, FC, cloneElement } from "react";
import * as AccordionStyles from "./Accordion.styles";
import { AccordionContextProvider } from "../Accordion.context";
import { AccordionItemProps } from "../AccordionItem/AccordionItem";

type AccordionProps = React.ComponentPropsWithoutRef<"div"> & {
  defaultIndex?: number;
  children: React.ReactElement<AccordionItemProps>[];
};

const Accordion: FC<AccordionProps> = (props) => {
  const { children, defaultIndex } = props;

  const clonedChildren = Children.map(children, (child, index) =>
    cloneElement(child, { index })
  );

  return (
    <AccordionContextProvider activeIndex={defaultIndex}>
      <AccordionStyles.Container>{clonedChildren}</AccordionStyles.Container>
    </AccordionContextProvider>
  );
};

export default Accordion;
