import { FC } from "react";

const ChevronDown: FC<React.SVGAttributes<SVGElement>> = (props) => (
  <svg
    width="19"
    height="12"
    viewBox="0 0 19 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.663391 1.01008C1.30789 0.354471 2.36327 0.349961 3.01335 1.00004L9.51331 7.5L15.9749 1.00462C16.6252 0.350873 17.6828 0.349493 18.3348 1.00154C18.9857 1.65239 18.9857 2.70761 18.3348 3.35846L10.6918 11.0015C10.0409 11.6524 8.98567 11.6524 8.33479 11.0015L0.673349 3.34004C0.0310783 2.69777 0.0266323 1.65782 0.663391 1.01008Z"
      fill="#404040"
    />
  </svg>
);

export default ChevronDown;
