import { css } from "@emotion/react";
import styled from "@emotion/styled";
import _ from "lodash";

export const Container = styled.div``;

type InputProps = {
  isError: boolean;
};

export const Input = styled.input<InputProps>`
  ${({ theme }) =>
    css(_.omit(theme.components.input, "label", "wrapper", "helper", "error"))};

  &:focus-within ~ label,
  &:not(:placeholder-shown) ~ label {
    bottom: calc(100% - 28px);
    font-size: 0.75rem;
    font-weight: 700;
  }

  border: 1px solid
    ${({ isError, theme }) => (isError ? theme.colors.error : "#ccc")};
`;

export const Wrapper = styled.div`
  ${({ theme }) => css(theme.components.input.wrapper)};
`;

type LabelProps = {
  isRequired: boolean;
};

export const Label = styled.label<LabelProps>`
  ${({ theme }) => css(theme.components.input.label)};

  ${({ isRequired, theme }) =>
    isRequired &&
    css`
      &::after {
        content: "*";
        color: ${theme.colors.error};
        margin-left: 4px;
      }
    `}
`;

export const Helper = styled.p`
  ${({ theme }) => css(theme.components.input.helper)};
`;

export const Error = styled.p`
  ${({ theme }) => css(theme.components.input.error)};
`;
