import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonProps } from "./Button";

export const Button = styled.button<ButtonProps>`
  ${({ theme }) => css(theme.components.button)}

  gap: ${({ icon }) => (icon ? "16px" : "unset")};

  ${({ theme, variant }) => {
    if (variant === "outlined") {
      return css`
        background-color: #ffffff;
        border: 1px solid ${theme.colors.red};
        color: ${theme.colors.red};
      `;
    }

    if (variant === "text") {
      return css`
        background-color: transparent;
        border: none;
        color: ${theme.colors.red};
      `;
    }

    return css`
      background-color: ${theme.colors.red};
      border: 1px solid ${theme.colors.red};
      color: #ffffff;
    `;
  }}
`;

export const Icon = styled.div`
  display: flex;
`;
