/** @jsxImportSource @emotion/react */

import React, { ElementType, FC } from "react";
import * as TextStyles from "./Text.styles";
import { mq } from "src/core/emotion/breakpoints";
import { MediaQueryStyles } from "src/types/emotion";

type TextProps = Omit<React.ComponentPropsWithoutRef<"p">, "color"> & {
  as?: ElementType;
} & MediaQueryStyles;

const Text: FC<TextProps> = (props) => {
  const { children, ...restProps } = props;

  const style = restProps;
  const css = mq(style);

  return (
    <TextStyles.Text css={css} {...restProps}>
      {children}
    </TextStyles.Text>
  );
};

export default Text;
