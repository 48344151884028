/** @jsxImportSource @emotion/react */

import React, { ChangeEvent, FC, useRef, useState } from "react";
import * as CheckboxStyles from "./Checkbox.styles";
import { mq } from "src/core/emotion/breakpoints";

type CheckboxProps = React.ComponentPropsWithoutRef<"input"> & {
  label?: string;
};

const Checkbox: FC<CheckboxProps> = (props) => {
  const {
    label,
    name = "checkbox",
    checked: _checked = false,
    type = "checkbox",
    onChange = () => {},
    ...restProps
  } = props;
  const [checked, setChecked] = useState(_checked);
  const ref = useRef<HTMLInputElement>(null);

  const style = restProps;
  const css = mq(style);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
    onChange(e);
  };

  const handleIconClick = () => {
    ref.current?.click();
  };

  return (
    <CheckboxStyles.Container>
      <CheckboxStyles.Wrapper>
        <CheckboxStyles.Checkbox
          {...restProps}
          ref={ref}
          id={name}
          name={name}
          css={css}
          type={type}
          onChange={handleChange}
        />
        {checked && <CheckboxStyles.Check onClick={handleIconClick} />}
      </CheckboxStyles.Wrapper>
      <CheckboxStyles.Label htmlFor={name}>{label}</CheckboxStyles.Label>
    </CheckboxStyles.Container>
  );
};

export default Checkbox;
