import { useState, useEffect } from "react";
import Link from "next/link";
import { dynamicUrl } from "../utils/dynamic-url";

type SliderProps = {
  data: Array<any>;
  infiniteScroll?: boolean;
  autoScroll?: boolean;
};

export const Slider = ({
  data,
  infiniteScroll = true,
  autoScroll = false
}: SliderProps) => {
  const [winSize, setWinSize] = useState(null);
  const [slideView, setSlideView] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const wrapperWidth = document.getElementById("outer-wrapper").parentElement
      .offsetWidth;
    setWinSize(wrapperWidth);
    scrollNext();
  }, []);

  useEffect(() => {
    if (autoScroll) {
      const timer = setTimeout(() => {
        scrollNext();
      }, 3000);
      return () => clearTimeout(timer);
    }
  });

  const scrollNext = () => {
    if (data && data.length <= 1) {
      return;
    }
    if (slideView === winSize * (data && data.length) - winSize) {
      setSlideView(0);
    } else setSlideView(slideView + winSize);
  };

  const activeSlides = (e) => {
    if (data && data.length <= 1) {
      return;
    }

    const maxSlide = winSize * (data && data.length) - winSize;
    if (infiniteScroll) {
      if (e.target.id === "prev") {
        if (slideView === 0) {
          setSlideView(maxSlide);
        } else setSlideView(slideView - winSize);
      }
      if (e.target.id == "next") {
        if (slideView === maxSlide) {
          setSlideView(0);
        } else setSlideView(slideView + winSize);
      }
    } else {
      if (e.target.id === "prev") {
        if (slideView === 0) {
          setSlideView(0);
        } else setSlideView(slideView - winSize);
      }
      if (e.target.id == "next") {
        if (slideView === maxSlide) {
          setSlideView(maxSlide);
        } else setSlideView(slideView + winSize);
      }
    }
  };

  const handleClick = (key) => {
    setSlideView(winSize * key);
  };

  return (
    <div className="slider-outer-wrapper" id="outer-wrapper">
      <div className="slider-wrapper">
        <div className="item-wrapper">
          <div className="item" id="item">
            {data &&
              data.map((data, key) => {
                return (
                  <Link href={dynamicUrl(data.banner_slug)} as={data.banner_slug} legacyBehavior>
                    <a>
                      <img
                        key={key}
                        className="item-img"
                        id="item-img"
                        src={
                          winSize < 640
                            ? data.banner_mobile
                            : data.banner_desktop
                        }
                        onLoad={() =>
                          setHeight(
                            document.getElementById("item-img").clientHeight
                          )
                        }
                        alt={data.banner_desktop_name}
                      ></img>
                    </a>
                  </Link>
                );
              })}
          </div>
        </div>
        <div className="slider-dots-container">
          <div className="slider-dots-wrapper">
            {data &&
              data.length > 1 &&
              data.map((data, key) => {
                return (
                  <button
                    key={data.banner_desktop_name}
                    style={{
                      opacity: slideView === key * winSize ? "1" : "0.25"
                    }}
                    className="slider-dots"
                    onClick={() => handleClick(key)}
                  ></button>
                );
              })}
          </div>
        </div>
        {data && data.length > 1 && (
          <div className="button-wrapper">
            <button
              className={winSize > 600 ? "arrow prev" : "arrow-hidden"}
              id="prev"
              onClick={(e) => activeSlides(e)}
            >
              &lt;
            </button>
            <button
              className={winSize > 600 ? "arrow next" : "arrow-hidden"}
              id="next"
              onClick={(e) => activeSlides(e)}
            >
              &gt;
            </button>
          </div>
        )}
      </div>
      <style jsx>
        {`
          .slider-outer-wrapper {
            display: flex;
            align-items: center;
            justify-self: center;
          }
          .slider-wrapper {
            width: ${winSize}px;
            overflow: auto;
            scroll-behavior: smooth;
            overflow-x: hidden;
          }
          .item {
            display: flex;
            color: white;
            scroll-behavior: smooth;
            transform: translateX(-${slideView}px);
            transition: transform 330ms ease-in-out;
          }
          .item-wrapper {
            display: flex;
            width: ${winSize}px;
          }
          .item-img {
            width: ${winSize}px;
          }
          /* ARROW*/
          .button-wrapper {
            width: ${winSize}px;
            position: absolute;
            display: flex;
            justify-content: space-between;
            z-index: 1;
            margin-top: calc(-${height}px / 2);
          }
          .arrow:hover {
            cursor: pointer;
          }
          .prev {
            left: 0;
          }
          .next {
            right: 0;
          }
          .arrow {
            font-size: 24px;
            background-color: white;
            outline: none;
            border: none;
            border-radius: 100%;
            margin: 0px 16px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            height: 35px;
            width: 35px;
          }
          .arrow-hidden {
            display: none;
          }
          /* SLIDER DOTS */
          .slider-dots-container {
            display: flex;
            justify-content: center;
            position: absolute;
            z-index: 2;
            margin-top: -48px;
            width: ${winSize}px;
          }
          .slider-dots {
            border-radius: 100%;
            border: solid 8px;
            margin: 10px;
            outline: none;
          }
          .slider-dots {
            position: relative;
          }
          .slider-dots:hover {
            cursor: pointer;
          }

          @media only screen and (max-width: 640px) {
            /* ARROW */
            .arrow {
              display: none;
            }
            /* SLIDER DOTS */
            .slider-dots-container {
              margin-top: -38px;
            }
            .slider-dots {
              border-radius: 100%;
              border: solid 4px;
              margin: 10px;
              outline: none;
            }
          }
        `}
      </style>
    </div>
  );
};
