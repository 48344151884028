import React, { useState, useEffect } from "react";
import CompanySlugs from "../../data/DataSlugs";
import Link from "next/link";
import LogoRed from "../../../public/static/images/svg/homecredit_red.svg";
import ArrowRight from "../../../public/static/images/svg/arrow_right.svg";
import ArrowLeft from "../../../public/static/images/svg/arrow_back.svg";
import phoneIcon from "../../../public/static/images/svg/phone_footer_idle.svg";
import searchIcon from "../../../public/static/images/svg/search.svg";
import burgerIcon from "../../../public/static/images/svg/Burger.svg";
import UseWindowSize from "../UseWindowSize";
import loginIcon from "../../../public/static/images/svg/DEF85517-1553-4BF8-9599-DE566EB7A52E.svg";
import { getCookie, deleteCookie } from "../../utils/helpers";
import { useRouter } from "next/router";
import fetch from "../../utils/fetch";
import { GACustomEvent } from "src/utils/analytics";
import { Link as MagnoliaLink } from "src/components/Magnolia";

const NavbarStyle = () => {
  return (
    <style jsx="true">{`
      ul {
        list-style-type: none;
        padding: 0px;
      }
      .button {
        padding-bottom: 5px;
      }
      .dropdown-parent a {
        display: flex;
        justify-content: space-between;
      }
      .container-btn-new {
        width: 50%;
        justify-content: flex-end;
        display: flex;
      }
      .icon-new {
        width: 50px;
        background: var(--color-red);
        color: var(--color-white);
        border-radius: 12px;
        text-align: center;
        margin-left: 5px;
        height: 20px;
      }
      .icon-new--height {
        height: 12px;
      }
      .product-dropdown-wrapper div {
        padding: 0px 0px 8px 0px;
        color: var(--color-white);
        font-weight: normal;
        font-size: 12px;
      }
      .parent-hover:hover {
        cursor: pointer;
        color: var(--color-red);
      }
      .product-dropdown-wrapper ul {
        margin-left: 16px;
      }
      .noMargin {
        margin: 0px;
      }
      .navbar-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: solid 1px var(--color-gray-lighter);
        font-weight: bold;
        background-color: var(--color-white);
        top: 0px;
        position: -webkit-sticky;
        position: sticky;
        z-index: 20;
      }
      .navbar-logo {
        margin: 10px;
        height: 16px;
      }
      .navbar-logo:hover {
        cursor: pointer;
      }
      .navbar-contact {
        font-size: 14px;
        display: flex;
        align-items: center;
        color: var(--color-gray);
      }
      .navbar-contact p {
        margin: 0 0 0 10px;
      }
      .navbar-container {
        height: 64px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 960px;
      }
      .hamburger-item-container {
        padding: 12px 12px 28px 28px;
      }
      .phone-container {
        display: flex;
        justify-content: space-between;
        color: var(--color-white-dark);
      }
      .phone-container p {
        margin: 10px;
      }
      .phone-wrapper {
        color: var(--color-white);
        display: flex;
        align-items: center;
      }
      .hamburger-links {
        margin: 10px;
      }
      .dropdown-content {
        display: none;
      }
      .dropdown-content li {
        margin-bottom: 8px;
      }
      .drop:hover {
        color: var(--color-red);
      }
      .drop:hover .dropdown-content {
        display: flex;
        font-size: 12px;
        opacity: 0.9;
        background-color: var(--color-gray);
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.19);
        position: absolute;
        min-width: 160px;
        padding: 20px 30px 20px 30px;
        z-index: 1;
      }
      .drop:hover .dropdown-content .list-product {
        width: 72%;
      }
      .buttonRight {
        display: flex;
        align-items: center;
        height: 100%;
      }
      .button {
        display: flex;
        align-items: center;
        margin-right: 24px;
        height: 100%;
      }
      .button:hover {
        color: var(--color-red);
        cursor: pointer;
      }
      .button-active {
        color: var(--color-red);
      }
      .drop-link {
        color: var(--color-white);
        font-size: 12px;
        font-weight: lighter;
      }
      .drop-link:hover {
        color: var(--color-red);
      }
      .navbar-logo {
        color: var(--color-red);
        font-weight: bold;
        font-size: 20px;
      }
      .components-outer-wrapper {
        display: flex;
      }
      .navbar-components {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
      }
      .navbar-components-wrapper {
        display: flex;
        position: relative;
      }
      .navbar-list {
        display: flex;
        padding: 0%;
        height: 100%;
      }
      .navbar-list li {
        display: flex;
      }
      .search-input-container {
        display: none;
      }
      .search-container {
        max-width: 64px;
        display: flex;
        background-color: var(--color-gray);
        transition: all 0.3s ease;
      }
      .search-container:hover {
        cursor: pointer;
      }
      .search {
        background-color: var(--color-white);
        overflow: hidden;
        transition: all 0.3s ease;
        height: 64px;
        width: 64px;
        display: none;
        justify-content: center;
        align-items: center;
      }
      .search-icon {
        background-color: var(--color-white);
      }
      .search-input {
        display: none;
        position: absolute;
        height: 64px;
        caret-color: var(--color-white);
      }
      .search-input-active {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: var(--color-gray);
        height: 100%;
        width: 100%;
        position: absolute;
        animation: search 0.5s;
      }
      .user-input {
        background: none;
        border: none;
        border-bottom: solid 1px var(--color-white);
        width: 90%;
        font-size: 15px;
        padding-bottom: 4px;
        caret-color: var(--color-white);
        color: var(--color-white-dark);
      }
      .searchActive {
        height: 64px;
        width: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;
      }
      .hamburger-menu-icon {
        width: 64px;
        height: 100%;
        display: none;
      }
      .hamburger-menu-container-active {
        top: 0%;
        width: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.9);
        height: 100%;
        overflow: auto;
        position: fixed;
      }
      .hamburger-menu-container {
        display: none;
      }
      .back-icon {
        float: right;
        margin: 10px;
      }

      /*LOGIN BUTTON*/

      .login-dropdown-container {
        display: flex;
        flex-direction: column;
      }
      .login-dropdown {
        display: none;
        position: absolute;
        background-color: var(--color-white);
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        width: 100px;
        margin: 23px;
        border-radius: 5px;
        right: -23px;
      }
      .login-dropdown-container:hover .login-dropdown {
        cursor: pointer;
        display: block;
      }
      .login-item {
        font-size: 12px;
        margin: 0px;
        padding: 8px 16px;
      }
      .login-dropdown a:nth-child(odd) p {
        border-bottom: solid 1px var(--color-white-soft);
      }
      .login-dropdown button {
        background-color: var(--color-white);
        border: none;
        text-align: center;
        border-radius: 5px;
      }

      @keyframes search {
        from {
          right: 0px;
          width: 0%;
        }
        to {
          right: 0px;
          width: 100%;
        }
      }
      .login-button-wrapper {
        display: none;
      }
      @media only screen and (max-width: 700px) {
        .container-btn-new {
          display: flex;
          position: absolute;
          right: 10vw;
        }
        .navbar-container {
          display: flex;
          justify-content: space-between;
        }
        .components-outer-wrapper {
          display: none;
        }
        .hamburger-menu-icon {
          display: flex;
          justify-content: center;
          align-content: center;
        }
        .hamburger-icon {
          width: 50%;
          align-self: center;
        }
        .hamburger-links li {
          margin-bottom: 8px;
        }
        .navbar-contact p {
          color: var(--color-white);
        }
        .drop-link {
          font-size: 12px;
          color: var(--color-white-dark);
        }
        .hamburger-title {
          color: var(--color-white-dark);
          margin-top: 14px;
          font-size: 16px;
          font-weight: bolder;
        }
        .user-input {
          margin-left: 10px;
          margin-right: 26px;
          -webkit-appearance: none;
        }
        .login-button-wrapper {
          display: flex;
        }
        .login-dropdown {
          display: block;
          right: -8px;
        }
      }
      @media only screen and (min-width: 700px) {
        .hamburger-menu-container-active {
          display: none;
        }
      }
    `}</style>
  );
};

const Button = ({ buttonName, click }) => {
  return (
    <div onClick={click} className="button">
      <div>{buttonName}</div>
      <img src={ArrowRight} alt="arrow-open" />
    </div>
  );
};

const List = ({ listName, listClass, href, url, newBtn, onClick }) => {
  return (
    <li onClick={onClick}>
      <Link href={href} as={`/${url}`} legacyBehavior>
        <a className={listClass}>
          {listName}
          {newBtn ? <div className="icon-new">BARU</div> : ""}
        </a>
      </Link>
      <style jsx>{`
        li {
          font-size: 12px;
          list-style-type: none;
        }
      `}</style>
    </li>
  );
};

const ProductDropDown = () => {
  return (
    <div className="product-dropdown-wrapper" data-cy="navbar-dropdown-1">
      <div className="dropdown-parent">
        <Link href="/belanja-di-toko" as="/belanja-di-toko" legacyBehavior>
          <a>
            <div className="parent-hover">Belanja di Toko</div>
          </a>
        </Link>
      </div>
      <div className="dropdown-parent">
        <Link href="/pembiayaan-tunai" as="/pembiayaan-tunai" legacyBehavior>
          <a>
            <div className="parent-hover">Pembiayaan Tunai</div>
          </a>
        </Link>
      </div>
      {/* <div className="dropdown-parent">
        <Link href="/kartukredit" as="/kartukredit">
          <a>
            <div className="parent-hover">Kartu Kredit</div>
          </a>
        </Link>
      </div>
      <div className="dropdown-parent">
        <Link href="/uang-elektronik" as="/uang-elektronik">
          <a>
            <div className="parent-hover" style={{ whiteSpace: "nowrap" }}>
              Home Credit Pay
            </div>
          </a>
        </Link>
      </div> */}
      {/* 
      HIDE PAYLATER
      <div className="dropdown-parent">
        <Link href="/bayarnanti" as="/bayarnanti">
          <a>
            <div className="parent-hover" style={{ whiteSpace: "nowrap" }}>
              Home Credit BayarNanti
            </div>
          </a>
        </Link>
      </div> */}
      {/* HIDE MPF
      <div className="dropdown-parent">
        <Link href="/kredit-multiguna" as="/kredit-multiguna">
          <a>
            <div className="parent-hover">Pembiayaan Multiguna</div>
          </a>
        </Link>
      </div>
      <ul>
        <List
          listClass="drop-link"
          listName="FlexiFast"
          href="/kredit-multiguna/pinjaman-tunai-pelanggan-lama-tanpa-jaminan"
          url="kredit-multiguna/pinjaman-tunai-pelanggan-lama-tanpa-jaminan"
        />
      </ul> */}
      <Link href="/proteksi" as="/proteksi" legacyBehavior>
        <a>
          <div className="parent-hover">Proteksi</div>
        </a>
      </Link>
      <ul>
        <List
          listClass="drop-link"
          listName="AMAN"
          href="/asuransi-cicilan-kredit"
          url="asuransi-cicilan-kredit"
        />
        <List
          listClass="drop-link"
          listName="EASYCOVER"
          href="/asuransi-gadget"
          url="asuransi-gadget"
        />
        <List
          listClass="drop-link"
          listName="proSERIES / TEC PROTEC"
          href="/asuransi-barang-elektronik"
          url="asuransi-barang-elektronik"
        />
        <List
          listClass="drop-link"
          listName="SANTAI"
          href="/asuransi-isi-rumah"
          url="asuransi-isi-rumah"
        />
        <List
          listClass="drop-link"
          listName="PROTEXI"
          href="/asuransi-tagihan-kartu-kredit"
          url="asuransi-tagihan-kartu-kredit"
        />
        <List
          listClass="drop-link"
          listName="CriticaLifeCOVER"
          href="/CriticaLifeCOVER"
          url="CriticaLifeCOVER"
        />
      </ul>
    </div>
  );
};

const PromoDropdown = () => {
  return (
    <div className="product-dropdown-wrapper" data-cy="navbar-dropdown-2">
      <div className="dropdown-parent">
        <MagnoliaLink
          href="/promo-dan-penawaran"
          onClick={() =>
            GACustomEvent("multiple_screens_promo_offers_page", "click")
          }
        >
          <div className="parent-hover">Promosi</div>
        </MagnoliaLink>
      </div>
      <div className="dropdown-parent">
        <Link href="/rewards" as="/rewards" legacyBehavior>
          <a>
            <div className="parent-hover">Home Credit Rewards</div>
          </a>
        </Link>
      </div>
    </div>
  );
};

const TentangKamiDropDown = () => {
  return (
    <div className="product-dropdown-wrapper" data-cy="navbar-dropdown-2">
      <div className="dropdown-parent">
        <Link href="/tentang-perusahaan" as="/tentang-perusahaan" legacyBehavior>
          <a>
            <div className="parent-hover">Tentang Perusahaan</div>
          </a>
        </Link>
      </div>
      <div className="dropdown-parent">
        <Link href="/literasi-keuangan" as="/literasi-keuangan" legacyBehavior>
          <a>
            <div className="parent-hover">Literasi Keuangan</div>
          </a>
        </Link>
      </div>
      <div className="dropdown-parent">
        <Link href="/blog" as="/blog" legacyBehavior>
          <a>
            <div className="parent-hover">Blog</div>
          </a>
        </Link>
      </div>
      <div className="dropdown-parent">
        <Link
          href={"/${CompanySlugs.tentangPerusahaan}/${CompanySlugs.siaranPers}"}
          as="/tentang-perusahaan/siaran-pers"
          legacyBehavior>
          <a>
            <div className="parent-hover">Siaran Pers</div>
          </a>
        </Link>
      </div>
    </div>
  );
};

const LoginDropdown = ({ loggedIn }) => {
  const router = useRouter();
  const handleLogout = async () => {
    const token = getCookie("token");
    const userName = localStorage.getItem("userName");
    const logout = await fetch(
      "DELETE",
      "/delete-token",
      { data: { authToken: token, userName: userName } },
      true
    );
    localStorage.removeItem("userName");
    deleteCookie("rtoken");
    deleteCookie("token");
    deleteCookie("idcsu");
    sessionStorage.removeItem("cuid");
    localStorage.removeItem("contract-list");
    if (window.location.pathname === "/") {
      location.reload();
    } else router.push("/");
  };
  return (
    <div className="login-dropdown">
      {loggedIn ? (
        <>
          <Link href="/dashboard" as="/dashboard" legacyBehavior>
            <a>
              <p className="login-item">Dashboard</p>
            </a>
          </Link>
          <button className="login-item" onClick={() => handleLogout()}>
            Logout
          </button>
        </>
      ) : (
        <>
          <a
            href={process.env.credstore + process.env.clientId}
            data-cy="login"
          >
            <p className="login-item">Log In</p>
          </a>
          {/* <a
            href={process.env.credstoreRegister + process.env.clientId}
            data-cy="register"
          >
            <p className="login-item">Daftar</p>
          </a> */}
        </>
      )}

      <style jsx>{`
        .login-item {
        }
      `}</style>
    </div>
  );
};

const NavigationBar = () => {
  const [burgerMenu, setBurgerMenu] = useState(false);
  const [winSize, setWinSize] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [haveCookie, setHaveCookie] = useState(false);

  const { width } = UseWindowSize();

  useEffect(() => {
    setWinSize(width);
    if (getCookie("rtoken") !== null) {
      setHaveCookie(true);
    }
  }, []);

  const handleClickBurger = () => {
    if (burgerMenu === false) {
      setBurgerMenu(true);
    } else setBurgerMenu(false);
  };

  return (
    <div className="navbar-wrapper">
      <div className="navbar-container">
        <Link href="/" legacyBehavior>
          <img className="navbar-logo" src={LogoRed} alt="logo" />
        </Link>
        <div className="components-outer-wrapper">
          <div className="navbar-components-wrapper">
            <div className="navbar-components">
              <div className="tel-login-container">
                <a href="tel:+622129539600" target="_blank">
                  <div className="navbar-contact">
                    <img src={phoneIcon} />
                    <p>021 2953 9600</p>
                  </div>
                </a>
                <div className="login-dropdown-container">
                  {haveCookie ? (
                    <div className="login-icon">
                      <img src={loginIcon}></img>
                    </div>
                  ) : (
                    <button className="login-button">Masuk</button>
                  )}
                  <LoginDropdown
                    loggedIn={haveCookie ? true : false}
                  ></LoginDropdown>
                </div>
              </div>
              <div className="button-container">
                <ul className="navbar-list">
                  <li>
                    <div className="drop">
                      <Button buttonName="Layanan" />
                      <div className="dropdown-content">
                        <ProductDropDown />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="drop">
                      <Button buttonName="Promo & Penawaran" />
                      <div className="dropdown-content">
                        <PromoDropdown></PromoDropdown>
                      </div>
                    </div>
                  </li>
                  <List
                    listClass="button"
                    listName="Home Credit Go"
                    href="/go-ers"
                    url="go-ers"
                    onClick={() =>
                      GACustomEvent(
                        "multiple_screens_home_credit_go_page",
                        "click"
                      )
                    }
                  />
                  <List
                    listClass="button"
                    listName="Mitra"
                    href="/mitra"
                    url="mitra"
                    onClick={() =>
                      GACustomEvent(
                        "multiple_screens_mitra_partner_page",
                        "click"
                      )
                    }
                  />
                  <li>
                    <div className="drop">
                      <Button buttonName="Tentang Kami" />
                      <div className="dropdown-content">
                        <TentangKamiDropDown></TentangKamiDropDown>
                      </div>
                    </div>
                  </li>
                  <List
                    listClass="buttonRight parent-hover"
                    listName="Temukan Lokasi Kami"
                    href="/temukan-lokasi-kami"
                    url="temukan-lokasi-kami"
                    onClick={() =>
                      GACustomEvent("multiple_screens_locate_us_page", "click")
                    }
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="burger-login-container">
          <div
            className="hamburger-menu-icon"
            onClick={() => handleClickBurger()}
          >
            <img className="hamburger-icon" src={burgerIcon} alt="burger-bar" />
          </div>
          {winSize < 700 && (
            <div className="login-button-wrapper">
              <div
                className="login-icon"
                onClick={() => setShowLogin(!showLogin)}
              >
                {haveCookie ? (
                  <img src={loginIcon}></img>
                ) : (
                  <button className="login-button">Masuk</button>
                )}
              </div>
              {showLogin && (
                <LoginDropdown
                  loggedIn={haveCookie ? true : false}
                ></LoginDropdown>
              )}
            </div>
          )}
        </div>

        <div
          className={
            burgerMenu
              ? "hamburger-menu-container-active"
              : "hamburger-menu-container"
          }
        >
          <div className="hamburger-item-container">
            <div className="hamburger-item">
              <div className="phone-container">
                <a href="tel:+622129539600" target="_blank">
                  <div className="phone-wrapper">
                    <img src={phoneIcon} />
                    <p>021 2953 9600</p>
                  </div>
                </a>
                <div className="back-icon" onClick={handleClickBurger}>
                  <img src={ArrowLeft} alt="arrow-close" />
                </div>
              </div>
              <div className="search-input-container">
                <img src={searchIcon} />
                <input className="user-input" />
              </div>
              <p className="hamburger-title">Layanan</p>
              <div className="hamburger-links">
                <ProductDropDown />
              </div>
            </div>
            <MagnoliaLink href="/promo-dan-penawaran">
              <p className="hamburger-title">Promosi</p>
            </MagnoliaLink>
            <Link href="/rewards" as="/rewards" legacyBehavior>
              <a>
                <p className="hamburger-title">Home Credit Rewards</p>
              </a>
            </Link>
            <Link href="/go-ers" as="/go-ers" legacyBehavior>
              <a className="hamburger-title">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>Home Credit Go</div>
                </div>
              </a>
            </Link>
            <Link href="/panduan-konsumen" as="/panduan-konsumen" legacyBehavior>
              <a>
                <p className="hamburger-title">Panduan Konsumen</p>
              </a>
            </Link>
            <Link href="/pertanyaan-umum" as="/pertanyaan-umum" legacyBehavior>
              <a>
                <p className="hamburger-title">Pertanyaan Umum</p>
              </a>
            </Link>
            <div className="hamburger-item">
              <Link href="/mitra" as="/mitra" legacyBehavior>
                <a>
                  <p className="hamburger-title">Mitra</p>
                </a>
              </Link>
            </div>
            <Link href="/temukan-lokasi-kami" as="/temukan-lokasi-kami" legacyBehavior>
              <a>
                <p className="hamburger-title">Temukan Lokasi Kami</p>
              </a>
            </Link>
            <Link href="/tentang-perusahaan" as="/tentang-perusahaan" legacyBehavior>
              <a>
                <p className="hamburger-title">Tentang Kami</p>
              </a>
            </Link>
            <Link href="/literasi-keuangan" as="/literasi-keuangan" legacyBehavior>
              <a>
                <p className="hamburger-title">Literasi Keuangan</p>
              </a>
            </Link>
            {/* hide career tab, Ref: https://devops.homecredit.co.id/browse/CAC-214 */}
            {/* <a href="https://career.homecredit.co.id/" target=" _blank">
              <p className="hamburger-title">Karir</p>
            </a> */}
          </div>
        </div>
      </div>
      <NavbarStyle />
      <style jsx>{`
        li {
          font-size: 12px;
          list-style-type: none;
        }
        .tel-login-container {
          display: flex;
          align-items: center;
        }
        .login-button {
          width: 64px;
          color: white;
          background-color: var(--color-red);
          border: none;
          border-radius: 4px;
          padding: 4px;
          margin-left: 24px;
        }
        .burger-login-container {
          display: flex;
          align-items: center;
          margin-right: 16px;
        }
        .burger-login-container .login-button {
          margin-left: 0px;
        }
        .login-icon {
          width: 24px;
          margin-left: 24px;
          display: flex;
        }
        @media only screen and (max-width: 700px) {
          .login-icon {
            margin-right: 16px;
            margin-left: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default NavigationBar;
