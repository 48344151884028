import { formatRupiah } from 'src/utils/moneyFormat'
import { Box, Button, Text } from '../../UI'
import CalculatorSectionWrapper from './CalculatorSectionWrapper'

type CalculatorResultSectionProps = {
  values?: { price: number; downPayment: number; installmentTerm: number; installment: number }
}

const CalculatorResultSection = ({ values }: CalculatorResultSectionProps) => {
  const calculationItem = [
    { label: 'Harga Barang', item: values.price },
    { label: 'Jumlah Uang Muka (DP)', item: values.downPayment },
    { label: 'Jumlah Tenor (Bulan)', item: values.installmentTerm }
  ]
  const installment = values.installment ? formatRupiah(values.installment, true) : '-'
  const isFormFilled = values.installment ? true : false
  return (
    <CalculatorSectionWrapper
      title="Hasil Perhitungan"
      style={{ borderRadius: '0 16px 16px 0', background: '#F4F4F4' }}
    >
      <Text fontWeight={['500']} fontSize={['15px']} textAlign={['center']}>
        Cicilan mulai dari
      </Text>
      <Text textAlign={['center']} fontWeight={['700']} fontSize={['34px']} lineHeight={['48px']} color={['#3EAC58']}>
        Rp {installment}
      </Text>
      <Text fontWeight={['400']} fontSize={['12px']} textAlign={['center']} lineHeight={['16px']} margin={['16px 0']}>
        Perhitungan ini hanya estimasi dan sudah termasuk biaya admin serta biaya bulanan.
      </Text>
      <Box
        width={['auto']}
        borderRadius={['8px']}
        background={['#FFFFFF']}
        padding={['8px 24.55px']}
        justifyContent={['space-around']}
      >
        {calculationItem.map((data, key) => (
          <Box key={key} flexDirection={['row']} justifyContent={['space-between']}>
            <Text>{data.label}</Text>
            <Text fontSize={['16px']} fontWeight={['700']} lineHeight={['24px']} textAlign={['right']}>
              {key != 2 && 'Rp'}
              {data.item ? formatRupiah(data.item, true) : '-'}
            </Text>
          </Box>
        ))}
      </Box>
      <Text
        fontWeight={['700']}
        fontSize={['14px']}
        textAlign={['center']}
        lineHeight={['20px']}
        margin={['8px 0']}
        color={['#676767']}
      >
        Mau tahu jumlah cicilan yang pasti? Ajukan dengan download aplikasi My Home Credit.
      </Text>
      <a href="https://hcid.onelink.me/By7i/6fxuutsc" target="_blank">
        <Button borderRadius={['4px']} disabled={!isFormFilled} width={['100%']}>
          Ajukan Sekarang
        </Button>
      </a>
    </CalculatorSectionWrapper>
  )
}

export default CalculatorResultSection
