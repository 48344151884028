/** @jsxImportSource @emotion/react */

import React, { FC } from "react";
import * as InputStyles from "./Input.styles";
import { mq } from "src/core/emotion/breakpoints";

type InputProps = React.ComponentPropsWithoutRef<"input"> & {
  name: string;
  label?: string;
  errorMessage?: string;
  helperMessage?: string;
};

const Input: FC<InputProps> = (props) => {
  const {
    name,
    type = "text",
    label = "",
    placeholder = "",
    errorMessage = "",
    helperMessage = "",
    required = false,
    minLength,
    maxLength,
    onChange,
    ...restProps
  } = props;

  const dataTestId = restProps["data-testid"] || `input-${name}`;
  const hasLabel = Boolean(label);
  const style = restProps.style;
  const css = mq(style);
  const isRequired = Boolean(required);
  const isError = Boolean(errorMessage);
  const isNumber = Boolean(type === "number");

  // Handle input event to ensure only numeric values are allowed
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredValue = e.target.value.replace(/[^0-9]/g, "");

    e.target.value = filteredValue;
    if (onChange) onChange(e);
  };

  // if has label, render label and input
  if (hasLabel) {
    return (
      <InputStyles.Container>
        <InputStyles.Wrapper>
          <InputStyles.Input
            {...restProps}
            id={name}
            data-testid={dataTestId}
            name={name}
            type={type}
            placeholder={placeholder}
            css={css}
            isError={isError}
            minLength={minLength}
            maxLength={maxLength}
            {...(isNumber
              ? { onChange: handleChange, type: "text" }
              : { onChange })}
          />
          <InputStyles.Label htmlFor={name} isRequired={isRequired}>
            {label}
          </InputStyles.Label>
        </InputStyles.Wrapper>
        {!isError && <InputStyles.Helper>{helperMessage}</InputStyles.Helper>}
        {isError && <InputStyles.Error>{errorMessage}</InputStyles.Error>}
      </InputStyles.Container>
    );
  }

  // if no label, just render input
  return (
    <InputStyles.Container>
      <InputStyles.Input
        {...restProps}
        data-testid={dataTestId}
        name={name}
        type={type}
        placeholder={placeholder}
        css={css}
        isError={isError}
        minLength={minLength}
        maxLength={maxLength}
        onChange={isNumber ? handleChange : onChange}
      />
      <InputStyles.Helper>{helperMessage}</InputStyles.Helper>
    </InputStyles.Container>
  );
};

export default Input;
