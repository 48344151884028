type Units = "KB" | "MB";

const calculateUnitsToKB = (size: number, units: string) => {
  if (units === "KB") return size * 1024;
  if (units === "MB") return size * 1024 ** 2;
};

// Generate maxSize by maxSize and units
// example: maxSize = 2, units = "MB" then maxSizeMB = 2048
export const validateFileSize = (
  file: File,
  maxSize: number,
  units: Units = "KB"
): string => {
  if (!file) return "File tidak ditemukan";

  const maxSizeByKB = calculateUnitsToKB(maxSize, units);

  if (file.size > maxSizeByKB) {
    return `Ukuran file lebih dari ${maxSize} ${units}`;
  }

  return "";
};

export const validateFileType = (
  file: File,
  type: string | Array<string>
): string => {
  if (!file) return "File tidak ditemukan";

  if (Array.isArray(type) && !type.includes(file.type)) {
    return "Format file tidak didukung";
  }

  if (Array.isArray(type) && type.includes(file.type)) {
    return "";
  }

  if (file.type !== type) {
    return "Format file tidak didukung";
  }

  return "";
};

export const renameFileWithPrefix = (file: File, prefix: string): File => {
  const fileExtension = file.name.split(".").pop();
  const fileNameWithoutExtension = file.name.replace(`.${fileExtension}`, "");
  const newFileName = `${prefix}_${fileNameWithoutExtension}.${fileExtension}`;

  return new File([file], newFileName, { type: file.type });
};
