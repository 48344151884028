import styled from "@emotion/styled";

export const Container = styled.div`
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.32px;
  text-align: left;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
`;
