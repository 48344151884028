/** @jsxImportSource @emotion/react */

import React, { FC } from "react";
import * as HeadingStyles from "./Heading.styles";
import { mq } from "src/core/emotion/breakpoints";
import { MediaQueryStyles } from "src/types/emotion";

type HeadingProps = Omit<React.ComponentPropsWithoutRef<"div">, "color"> & {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
} & MediaQueryStyles;

const Heading: FC<HeadingProps> = (props) => {
  const { children, as, ...restProps } = props;

  const style = restProps;
  const css = mq(style);

  return (
    <HeadingStyles.Heading as={as} css={css} {...restProps}>
      {children}
    </HeadingStyles.Heading>
  );
};

export default Heading;
