// This breadcrumb is the default for Schema Markup language
export const defBreadcrumb = [
  {
    "@type": "ListItem",
    position: "1",
    item: {
      "@id": process.env.baseUrl,
      name: "Home",
    },
  },
];
// the end

export const ShopOnlineBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Belanja Online", href: "/kredit-online", to: "/kredit-online" },
];

export const ShopOfflineBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Belanja di Toko", href: "/belanja-di-toko", to: "/belanja-di-toko" },
];

export const PromoPenawaranBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Promo & Penawaran",
    href: "/promo-dan-penawaran",
    to: "/promo-dan-penawaran",
  },
];

export const RewardsBreadCrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Home Credit Rewards",
    href: "/rewards",
    to: "/rewards",
  },
];

export const pembiayaanMultigunaBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Pembiayaan Multiguna",
    href: "/kredit-multiguna",
    to: "/kredit-multiguna",
  },
];

export const ProteksiBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
];

export const SantaiBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
  { page: "Santai", href: "/asuransi-isi-rumah", to: "/asuransi-isi-rumah" },
];

export const SantaiMalaccaBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
  { page: "SANTAI", href: "/asuransi-isi-rumah/", to: "/asuransi-isi-rumah/" },
  {
    page: "Santai Malacca",
    href: "/asuransi-isi-rumah/Malacca",
    to: "/asuransi-isi-rumah/Malacca",
  },
];

export const SantaiMSIGBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
  { page: "SANTAI", href: "/asuransi-isi-rumah/", to: "/asuransi-isi-rumah/" },
  {
    page: "Santai MSIG",
    href: "/asuransi-isi-rumah/MSIG",
    to: "/asuransi-isi-rumah/MSIG",
  },
];

export const AmanBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
  {
    page: "Aman",
    href: "/asuransi-cicilan-kredit",
    to: "/asuransi-cicilan-kredit",
  },
];

export const MotocoverBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
];

export const ProSeriesBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
  {
    page: "proSERIES",
    href: "/asuransi-barang-elektronik",
    to: "/asuransi-barang-elektronik",
  },
];

export const caraPembayaranBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Cara Pembayaran", href: "/cara-pembayaran", to: "/cara-pembayaran" },
];

export const tentangPerusahaanBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Tentang Perusahaan",
    href: "/tentang-perusahaan",
    to: "/tentang-perusahaan",
  },
];

export const AsuransiGadgetBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
  {
    page: "EASYCOVER",
    href: "/asuransi-gadget",
    to: "/asuransi-gadget",
  },
];

export const acaraPerusahaanBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Tentang Perusahaan",
    href: "/tentang-perusahaan",
    to: "/tentang-perusahaan",
  },
  {
    page: "Acara Perusahaan",
    href: "/tentang-perusahaan/acara-perusahaan",
    to: "/tentang-perusahaan/acara-perusahaan",
  },
];

export const siaranPersBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Tentang Perusahaan",
    href: "/tentang-perusahaan",
    to: "/tentang-perusahaan",
  },
  {
    page: "Siaran Pers",
    href: "/tentang-perusahaan/siaran-pers",
    to: "/tentang-perusahaan/siaran-pers",
  },
];

export const penghargaanKamiBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Tentang Perusahaan",
    href: "/tentang-perusahaan",
    to: "/tentang-perusahaan",
  },
  {
    page: "Penghargaan Kami",
    href: "",
    to: "",
  },
];

export const kebijakanPrivasiBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Kebijakan Privasi",
    href: "/kebijakan-privasi",
    to: "/kebijakan-privasi",
  },
];

export const syaratDanKetentuanBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Syarat dan Ketentuan",
    href: "/syarat-dan-ketentuan",
    to: "/syarat-dan-ketentuan",
  },
];

export const blogBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Blog",
    href: "/blog",
    to: "/blog",
  },
];

export const blogCategoryBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Blog",
    href: "/blog",
    to: "/blog",
  },
  {
    page: "Blog Category",
    href: "/blog/category",
    to: "/blog/category",
  },
];

export const faqBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Pertanyaan Umum",
    href: "/pertanyaan-umum",
    to: "/pertanyaan-umum",
  },
];
export const ttdElektronikBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Panduan Konsumen",
    href: "/panduan-konsumen",
    to: "/panduan-konsumen",
  },
  {
    page: "Tanda Tangan Elektronik",
    href: "/panduan-konsumen/tanda-tangan-elektronik",
    to: "/panduan-konsumen/tanda-tangan-elektronik",
  },
];
export const panduanKonsumenBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Panduan Konsumen",
    href: "/panduan-konsumen",
    to: "/panduan-konsumen",
  },
];

export const biayaDendaBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Panduan Konsumen",
    href: "/panduan-konsumen",
    to: "/panduan-konsumen",
  },
  {
    page: "Biaya, Denda, dan Bunga ",
    href: "/biaya-denda-dan-bunga",
    to: "/biaya-denda-dan-bunga",
  },
];

export const mitraBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Mitra", href: "/mitra", to: "/mitra" },
];

export const lokasiKamiBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Temukan Lokasi Kami",
    href: "/temukan-lokasi-kami",
    to: "/temukan-lokasi-kami",
  },
];

export const hubungiKamiBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Hubungi Kami", href: "/hubungi-kami", to: "/hubungi-kami" },
];
export const keluhanBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Panduan Konsumen",
    href: "/panduan-konsumen",
    to: "/panduan-konsumen",
  },
  {
    page: "Keluhan",
    href: "/panduan-konsumen/keluhan",
    to: "/panduan-konsumen/keluhan",
  },
];
export const myHomeCreditAppBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Cara Pembayaran", href: "/cara-pembayaran", to: "/cara-pembayaran" },
  {
    page: "My Home Credit App",
    href: "/cara-pembayaran/my-home-credit-app",
    to: "/cara-pembayaran/my-home-credit-app",
  },
];

export const transferBankBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Cara Pembayaran", href: "/cara-pembayaran", to: "/cara-pembayaran" },
  {
    page: "Transfer Bank",
    href: "/cara-pembayaran/transfer-bank",
    to: "/cara-pembayaran/transfer-bank",
  },
];

export const geraiRetailBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Cara Pembayaran", href: "/cara-pembayaran", to: "/cara-pembayaran" },
  {
    page: "Gerai Retail",
    href: "/cara-pembayaran/gerai-retail",
    to: "/cara-pembayaran/gerai-retail",
  },
];

export const eCommerceBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Cara Pembayaran", href: "/cara-pembayaran", to: "/cara-pembayaran" },
  {
    page: "E-Commerce",
    href: "/cara-pembayaran/e-commerce",
    to: "/cara-pembayaran/e-commerce",
  },
];

export const eMoneyBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Cara Pembayaran", href: "/cara-pembayaran", to: "/cara-pembayaran" },
  {
    page: "Electronic Money",
    href: "/cara-pembayaran/electronic-money",
    to: " /cara-pembayaran/electronic-money",
  },
];

export const topUpSaldoHomeCreditPayreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Cara Pembayaran", href: "/cara-pembayaran", to: "/cara-pembayaran" },
  {
    page: "Isi Saldo Home Credit Pay",
    href: "/cara-pembayaran/isi-saldo-home-credit-pay",
    to: "/cara-pembayaran/isi-saldo-home-credit-pay",
  },
];

export const bayarOtomatisBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Cara Pembayaran",
    href: "/cara-pembayaran",
    to: "/cara-pembayaran",
  },
  {
    page: "Bayar Otomatis",
    href: "/cara-pembayaran/bayar-otomatis",
    to: "/cara-pembayaran/bayar-otomatis",
  },
];

export const flexiFastBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Pembiayaan Multiguna",
    href: "/kredit-multiguna",
    to: "/kredit-multiguna",
  },
  {
    page: "FlexiFast",
    href: "/kredit-multiguna/pinjaman-tunai-pelanggan-lama-tanpa-jaminan",
    to: "/kredit-multiguna/pinjaman-tunai-pelanggan-lama-tanpa-jaminan",
  },
];

export const twoWheelersBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Belanja di Toko",
    href: "/belanja-di-toko",
    to: "/belanja-di-toko",
  },
  {
    page: "Motor",
    href: "/kredit-motor",
    to: "/kredit-motor",
  },
];
export const lokasiKreditMotorBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Belanja di Toko",
    href: "/belanja-di-toko",
    to: "/belanja-di-toko",
  },
  {
    page: "Motor",
    href: "/kredit-motor",
    to: "/kredit-motor",
  },
  {
    page: "Alamat Lengkap Dealer Resmi",
    href: "/lokasi-kredit-motor",
    to: "/lokasi-kredit-motor",
  },
];

export const kartuKreditBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Kartu Kredit",
    href: "/kartukredit",
    to: "/kartukredit",
  },
];
export const ProtexiBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
  {
    page: "PROTEXI",
    href: "/asuransi-tagihan-kartu-kredit",
    to: "/asuransi-tagihan-kartu-kredit",
  },
];
export const HomeCreditGoBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Home Credit GO",
    href: "/go-ers",
    to: "/go-ers",
  },
];
export const CreditCardPromoBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Promo Home Credit Card",
    href: "/kartukredit/promo",
    to: "/kartukredit/promo",
  },
];

export const HomeCreditPayCepatBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Home Credit Pay", href: "/uang-elektronik", to: "/uang-elektronik" },
  {
    page: "Cepat",
    href: "/uang-elektronik/pembelian-dan-pembayaran",
    to: "/uang-elektronik/pembelian-dan-pembayaran",
  },
];
export const HCPBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Home Credit Pay",
    href: "/uang-elektronik",
    to: "/uang-elektronik",
  },
];
export const MyLifeBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
  {
    page: "MyLifeCOVER",
    href: "/MyLifeCOVER",
    to: "/MyLifeCOVER",
  },
];
export const SitemapBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Sitemap", href: "/sitemap", to: "/sitemap" },
];
export const CriticaLifeBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
  {
    page: "CriticaLifeCOVER",
    href: "/CriticaLifeCOVER",
    to: "/CriticaLifeCOVER",
  },
];

export const RideCoverBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Proteksi", href: "/proteksi", to: "/proteksi" },
  {
    page: "RideCOVER",
    href: "/RideCOVER",
    to: "/RideCOVER",
  },
];

export const PayLaterBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "BayarNanti", href: "/bayarnanti", to: "/bayarnanti" },
];

export const ModalUsahaBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  { page: "Modal Usaha Home Credit", href: "/modal-usaha", to: "/modal-usaha" },
];

export const SobatBelanjaBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "#SobatBelanja",
    href: "/sobatbelanja",
    to: "/sobatbelanja",
  },
];

export const LiterasiKeuanganBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Literasi Keuangan",
    href: "/literasi-keuangan",
    to: "/literasi-keuangan",
  },
];
export const CaraCerdasBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Literasi Keuangan",
    href: "/literasi-keuangan",
    to: "/literasi-keuangan",
  },
  {
    page: "Cara Cerdas",
    href: "/literasi-keuangan/cara-cerdas",
    to: "/literasi-keuangan/cara-cerdas",
  },
];
export const CashLoanBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Pembiayaan Tunai",
    href: "/pembiayaan-tunai",
    to: "/pembiayaan-tunai",
  },
];

export const CashLoanFlexiCashBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Pembiayaan Tunai",
    href: "/pembiayaan-tunai",
    to: "/pembiayaan-tunai",
  },
  {
    page: "FlexiCash",
    href: "/pembiayaan-tunai/flexicash",
    to: "/pembiayaan-tunai/flexicash",
  },
];

export const CashLoanKMGBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Pembiayaan Tunai",
    href: "/pembiayaan-tunai",
    to: "/pembiayaan-tunai",
  },
  {
    page: "Kredit Multi Guna",
    href: "/pembiayaan-tunai/kredit-multi-guna",
    to: "/pembiayaan-tunai/kredit-multi-guna",
  },
];

export const formulirSanggahanBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Panduan Konsumen",
    href: "/panduan-konsumen",
    to: "/panduan-konsumen",
  },
  {
    page: "Keluhan",
    href: "/panduan-konsumen/formulir-sanggahan",
    to: "/panduan-konsumen/formulir-sanggahan",
  },
];

export const PLOBreadcrumb = [
  { page: "Beranda", href: "/", to: "/" },
  {
    page: "Bayarnanti Belanja Online",
    href: "/bayarnanti-belanja-online",
    to: "/bayarnanti-belanja-online",
  },
];