import { FC } from "react";

const Pen: FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4361 4.70711C17.0456 4.31658 16.4124 4.31658 16.0219 4.70711L15.2605 5.46853C14.87 5.85905 14.87 6.49222 15.2605 6.88274L17.3818 9.00406C17.7723 9.39458 18.4055 9.39458 18.796 9.00406L19.5574 8.24264C19.948 7.85212 19.948 7.21895 19.5574 6.82843L17.4361 4.70711ZM14.7166 7.42664C14.3261 7.03611 13.6929 7.03611 13.3024 7.42664L5.83773 14.8913C5.65573 15.0733 5.55092 15.3184 5.54509 15.5757L5.49498 17.7867C5.48192 18.3629 5.95726 18.8308 6.53313 18.8086L8.69811 18.7254C8.94986 18.7157 9.18867 18.6114 9.36681 18.4333L16.8379 10.9622C17.2284 10.5716 17.2284 9.93848 16.8379 9.54796L14.7166 7.42664Z"
        fill="white"
      />
    </svg>
  );
};

export default Pen;
