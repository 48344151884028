/** @jsxImportSource @emotion/react */

import React, { FC } from "react";
import * as ButtonStyles from "./Button.styles";
import { mq } from "src/core/emotion/breakpoints";
import { MediaQueryStyles } from "src/types/emotion";

export type ButtonProps = React.ComponentPropsWithoutRef<"button"> & {
  icon?: React.ReactNode;
  variant?: "contained" | "outlined" | "text";
} & MediaQueryStyles;

const Button: FC<ButtonProps> = (props) => {
  const {
    type = "button",
    children,
    icon,
    variant = "contained",
    ...restProps
  } = props;

  const hasIcon = !!icon;

  const style = restProps;
  const css = mq(style);

  return (
    <ButtonStyles.Button
      type={type}
      css={css}
      icon={Boolean(icon)}
      variant={variant}
      {...restProps}
    >
      {hasIcon && <ButtonStyles.Icon>{icon}</ButtonStyles.Icon>}
      {children}
    </ButtonStyles.Button>
  );
};

export default Button;
