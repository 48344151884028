/** @jsxImportSource @emotion/react */
import React, { FC } from "react";
import * as AccordionButtonStyles from "./AccordionButton.styles";
import { ChevronDown } from "src/components/UI/Icon";
import { Box } from "src/components/UI";
import { useAccordionContext } from "../Accordion.context";

type AccordionButtonProps = React.ComponentPropsWithoutRef<"div"> & {
  index?: number;
};

const AccordionButton: FC<AccordionButtonProps> = (props) => {
  const { children, index } = props;

  const { activeIndex, setActiveIndex } = useAccordionContext();

  const isOpen = activeIndex === index;

  const handleClick = () => {
    if (!isOpen) {
      setActiveIndex(index);
      return;
    }

    setActiveIndex(null);
  };

  return (
    <AccordionButtonStyles.Container onClick={handleClick}>
      {children}

      <Box width={24} height={24} justifyContent="center" alignItems="center">
        <ChevronDown />
      </Box>
    </AccordionButtonStyles.Container>
  );
};

export default AccordionButton;
