/** @jsxImportSource @emotion/react */

import React, { ElementType, FC } from "react";
import * as BoxStyles from "./Box.styles";
import { MediaQueryStyles } from "src/types/emotion";
import { mq } from "src/core/emotion/breakpoints";

export type BoxProps = Omit<React.ComponentPropsWithoutRef<"div">, "color"> & {
  as?: ElementType;
} & MediaQueryStyles;

const Box: FC<BoxProps> = (props) => {
  const { children, as, ...restProps } = props;

  const style = restProps;
  const css = mq(style);

  return (
    <BoxStyles.Box as={as} {...restProps} css={css}>
      {children}
    </BoxStyles.Box>
  );
};

export default Box;
