import React, { FC, PropsWithChildren, createContext, useContext } from "react";
import { AccordionState, useAccordion } from "./useAccordion.hook";

type UseAccordion = ReturnType<typeof useAccordion>;

const AccordionContext = createContext<null | UseAccordion>(null);

export const AccordionContextProvider: FC<
  PropsWithChildren<Partial<AccordionState>>
> = (props) => {
  const { children, ...restProps } = props;
  const values = useAccordion(restProps);

  return (
    <AccordionContext.Provider value={values}>
      {children}
    </AccordionContext.Provider>
  );
};

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error(
      "To use this hook must be wrap with AccordionContextProvider"
    );
  }

  return context;
};
