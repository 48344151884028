import React, { useEffect, useState } from "react";
import Image from "next/image";
import { useFormik } from "formik";
import { Box, Button, Input, Text } from "../../UI";
import CalculatorSectionWrapper from "./CalculatorSectionWrapper";
import axios from "axios";
import { formatRupiah } from "src/utils/moneyFormat";
import { env } from "src/utils/env";

type CalculatorFormSectionProps = {
  setValues?: React.Dispatch<
    React.SetStateAction<{
      price: number;
      downPayment: number;
      installmentTerm: number;
      installment: number;
    }>
  >;
};

const base = {
  color: "#383838",
  border: "solid 1px #d1d1d1",
};
const clicked = {
  backgroundColor: "#ecf6ee",
  color: "#3eac58",
  border: "solid 1px #3eac58",
};

const CalculatorFormSection = ({ setValues }: CalculatorFormSectionProps) => {
  const [tenorList, setTenorList] = useState([]);
  const formCalculator = useFormik({
    initialValues: {
      price: undefined,
      downPayment: undefined,
      installmentTerm: undefined,
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        const response = await axios.post(
          `${env.baseUrl}/api/v1/simulation/calculator`,
          {
            ...(values.price !== undefined && {
              price: Number(values.price),
            }),
            ...(values.downPayment !== undefined && {
              downPayment: Number(values.downPayment),
            }),
            ...(values.installmentTerm !== undefined && {
              installmentTerm: Number(values.installmentTerm),
            }),
          }
        );
        if (response) {
          setValues({ ...values, installment: response.data.data.installment });
        }
      } catch (error) {
        const errors = error.response.data.error;
        setErrors(errors);
      }
    },
  });

  const handleTenor = (value) => {
    formCalculator.setFieldValue("installmentTerm", value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    formCalculator.handleSubmit();
  };

  const getTenor = async () => {
    const tenor = await axios.get(
      `${env.baseUrl}/api/v1/simulation/calculator`
    );
    setTenorList(tenor.data.data.installmentTermOptions);
  };

  useEffect(() => {
    getTenor();
  }, []);

  return (
    <CalculatorSectionWrapper
      title="Hitung Estimasi Cicilan"
      style={{ borderRadius: "16px 0 0 16px" }}
    >
      <Box as="form" id="calculator-form" onSubmit={handleSubmit}>
        <Box marginBottom={["16px"]}>
          <Input
            id="price"
            name="price"
            label="Harga Barang"
            type="number"
            value={
              formCalculator.values.price !== undefined
                ? formatRupiah(formCalculator.values.price)
                : formCalculator.values.price
            }
            onChange={formCalculator.handleChange}
            errorMessage={
              formCalculator.touched.price &&
              (formCalculator.errors.price as string)
            }
            maxLength={12}
          ></Input>
        </Box>
        <Box marginBottom={["16px"]}>
          <Input
            id="downPayment"
            name="downPayment"
            label="Jumlah Uang Muka"
            type="number"
            value={
              formCalculator.values.downPayment !== undefined
                ? formatRupiah(formCalculator.values.downPayment)
                : formCalculator.values.downPayment
            }
            onChange={formCalculator.handleChange}
            errorMessage={
              formCalculator.touched.downPayment &&
              (formCalculator.errors.downPayment as string)
            }
            maxLength={12}
          ></Input>
        </Box>
        <Text fontSize={["12px"]} fontWeight={["400px"]} lineHeight={["16px"]}>
          Tenor (bulan)
        </Text>
        {formCalculator.errors.installmentTerm && (
          <Box
            background={["#FFEAEC"]}
            height={["40px"]}
            borderRadius={["8px"]}
            alignItems={["center"]}
            flexDirection={"row"}
            padding={["12px"]}
            margin={["8px 0"]}
          >
            <Image
              width={24}
              height={24}
              src="/static/images/svg/alert_circle_outline.svg"
              alt="alert-circle-outline"
            />
            <Text
              color={["#BD0811"]}
              fontWeight={["600"]}
              letterSpacing={["0.004em"]}
              lineHeight={["16px"]}
              fontSize={["12px"]}
              marginLeft={["8px"]}
            >
              {formCalculator.errors.installmentTerm as string}
            </Text>
          </Box>
        )}
        <Box flexDirection={"row"} margin={["8px 0 24px"]}>
          {tenorList.map((data, key) => (
            <Button
              style={
                data === formCalculator.values.installmentTerm ? clicked : base
              }
              variant={"outlined"}
              key={key}
              width={"48px"}
              border={["solid 1px #D1D1D1"]}
              borderRadius={["8px"]}
              padding={["16px"]}
              textAlign={"center"}
              marginRight={["16px"]}
              onClick={() => handleTenor(data)}
            >
              {data}
            </Button>
          ))}
        </Box>
        <Button
          variant={"outlined"}
          borderRadius={["4px"]}
          type="submit"
          form="calculator-form"
        >
          Hitung Sekarang
        </Button>
      </Box>
    </CalculatorSectionWrapper>
  );
};

export default CalculatorFormSection;
