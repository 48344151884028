import { useReducer } from "react";

const initialState = {
  activeIndex: null,
};

export type AccordionState = typeof initialState;

enum ACCORDION_ACTION {
  SET_ACTIVE_INDEX = "SET_ACTIVE_INDEX",
}

const accordionReducer = (state, action) => {
  switch (action.type) {
    case ACCORDION_ACTION.SET_ACTIVE_INDEX:
      return { ...state, activeIndex: action.payload };
    default:
      return { ...state };
  }
};

export const useAccordion = (props: Partial<AccordionState>) => {
  const initialAccordionState = {
    ...initialState,
    ...props,
  };

  const [{ activeIndex }, dispatch] = useReducer(
    accordionReducer,
    initialAccordionState
  );

  const setActiveIndex = (newActiveIndex: number) => {
    return dispatch({
      type: ACCORDION_ACTION.SET_ACTIVE_INDEX,
      payload: newActiveIndex,
    });
  };

  return {
    activeIndex,
    setActiveIndex,
  };
};
