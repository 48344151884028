import { Global, css } from "@emotion/react";

const globalStyles = css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body {
    font-family: Roboto, sans-serif !important;
    font-size: 16px !important;
  }
`;

export const GlobalStyles = () => {
  return <Global styles={globalStyles} />;
};
