const Close: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4737 3.52648C19.7718 2.82451 18.6337 2.82451 17.9317 3.52648L12 9.45814L6.06853 3.52666C5.36656 2.82469 4.22845 2.82469 3.52648 3.52666C2.82451 4.22863 2.82451 5.36674 3.52648 6.06871L9.45796 12.0002L3.5267 17.9315C2.82473 18.6334 2.82473 19.7716 3.5267 20.4735C4.22867 21.1755 5.36679 21.1755 6.06875 20.4735L12 14.5423L17.9315 20.4737C18.6334 21.1757 19.7716 21.1757 20.4735 20.4737C21.1755 19.7717 21.1755 18.6336 20.4735 17.9316L14.5421 12.0002L20.4737 6.06853C21.1757 5.36656 21.1757 4.22845 20.4737 3.52648Z"
        fill="white"
      />
    </svg>
  );
};

export default Close;
