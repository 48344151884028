import React, { useEffect, useState } from "react";
import { SliderRelatedPromo } from "../UserInterface/SliderRelatedPromo";
import Link from "next/link";
import { ContractCard } from "./ContractCard";
import { getCookie } from "../../utils/helpers";
import NoContract from "./NoContract";

type HandlingContractProps = {
  rawDataContract?: any;
};

const HandlingContract = ({ rawDataContract }: HandlingContractProps) => {
  const [dataContracts, setDataContracts] = useState([]);

  const [haveCookie, setHaveCookie] = useState(false);
  const [outerWidth, setOuterWidth] = useState(0);

  const handleContractList = async () => {
    const el = document.getElementById("no-contract-outer");
    if (el) {
      setOuterWidth(el.offsetWidth);
    }
    if (rawDataContract && rawDataContract.contracts?.length > 0) {
      setDataContracts(
        rawDataContract.contracts.filter((data) => data.status === "SIGNED")
      );
    } else {
      if (localStorage.getItem("contract-list")) {
        const localStorageContractListData = JSON.parse(
          localStorage.getItem("contract-list")
        );
        const items = localStorageContractListData?.contracts?.filter(
          (data) => data.status === "SIGNED"
        );
        setDataContracts(items);
      }
    }
  };

  useEffect(() => {
    if (getCookie("rtoken") !== null) {
      handleContractList();
      setHaveCookie(true);
    }
  }, []);

  return <>
    {dataContracts && dataContracts.length !== 0 ? (
      <div className="contracts">
        <div className="all-contracts-wrapper">
          <p className="all-contracts-title">Kontrak Saya</p>
          <Link href="/dashboard/contract" as="/dashboard/contract" legacyBehavior>
            <a>
              <div className="all-contracts-button">Lihat Semua</div>
            </a>
          </Link>
        </div>
        <SliderRelatedPromo>
          {dataContracts.map((data, idx) => {
            if (data.status === "SIGNED") {
              return (
                <ContractCard
                  contractNo={data.contractNumber}
                  requestId={data.requestId}
                  isMistake={data.isMistake}
                  contractStatus="Active"
                  creditInfo={data.monthlyAmount}
                  contractCategory={data.productProfileCode}
                  dueDate={data.lastDueDate}
                  isCarousel={true}
                  key={idx}
                />
              );
            }
          })}
        </SliderRelatedPromo>
      </div>
    ) : (
      haveCookie && <NoContract outerWidth={outerWidth} />
    )}
    <style jsx>
      {`
        .contracts {
          max-width: 940px;
          align-self: center;
          width: 100%;
        }
        .all-contracts-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .all-contracts-button {
          color: var(--color-red);
          font-weight: bold;
          font-size: 12px;
          margin-right: 16px;
        }
        .all-contracts-title {
          font-weight: bold;
          margin-left: 16px;
          font-size: 16px;
        }

        .contract-skeleton {
          display: flex;
          justify-content: center;
          margin-top: 24px;
        }

        @media only screen and (max-width: 400px) {
          .all-contracts-button,
          .all-contracts-title {
            margin: 8px;
          }
        }
      `}
    </style>
  </>;
};
export default HandlingContract;
