import { CSSObject } from "@emotion/react";

type ThemeType = {
  colors: typeof colors;
  components: typeof components;
};

export const colors = {
  background: "#FFFFFF",
  text: "#404040",
  error: "#e11931",

  red: "#E11931",
  green: "#3eac58",
};

const components = {
  input: {
    width: "100%",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    fontSize: "1rem",
    height: "48px",
    color: "#404040",
    padding: "16px 8px 0px",
    gap: "8px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    background: "#F4F4F4",
    wrapper: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
    } as CSSObject,
    label: {
      fontFamily: "Roboto, sans-serif",
      position: "absolute",
      color: "#404040",
      fontWeight: 400,
      lineHeight: "24px",
      left: "8px",
      bottom: "2px",
      transition: "all 0.125s ease-in-out",
      cursor: "text",
    } as CSSObject,
    helper: {
      fontFamily: "Roboto, sans-serif",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.24px",
      textAlign: "left",
      color: "#676767",
      marginTop: "4px",
      padding: "0 8px",
    } as CSSObject,
    error: {
      fontFamily: "Roboto, sans-serif",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.24px",
      textAlign: "left",
      color: colors.error,
      marginTop: "4px",
      padding: "0 8px",
    } as CSSObject,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 16px",
    borderadius: "4px",
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: "16px",
    textTransform: "capitalize",
    border: "none",
    cursor: "pointer",
    ":disabled": {
      backgroundColor: "#cccccc",
      border: "1px solid #cccccc",
      cursor: "not-allowed",
    },
  } as CSSObject,
};

const theme: ThemeType = {
  colors,
  components,
};

export default theme;
