import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  font-family: Roboto, sans-serif;
`;

export const Input = styled.input`
  display: none;
`;

type PlaceholderProps = {
  isError?: boolean;
};

export const Placeholder = styled.div<PlaceholderProps>`
  width: 100%;
  height: 188px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  font-size: 16px;
  transition: border-color 0.2s;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 0px #4040401a;

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-align: center;
  color: ${({ isError, theme }) => (isError ? theme.colors.error : "#7b7b7b")};

  border-style: solid;
  border-width: 8px;
  border-color: #fff;
  box-shadow: 0px 1px 4px 0px #4040401a;
  background-color: #f4f4f4;
  cursor: pointer;

  & > img {
    width: 48px;
    height: auto;
  }

  &:hover {
    border-color: #eee;
  }
`;

export const Preview = styled.img`
  display: block;
  width: 100%;
  height: 184px;
  object-fit: cover;
  background-color: #f4f4f4;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
`;
