/** @jsxImportSource @emotion/react */
import React, { FC } from "react";
import * as AccordionPanelStyles from "./AccordionPanel.styles";
import { useAccordionContext } from "../Accordion.context";
import { mq } from "src/core/emotion/breakpoints";
import { MediaQueryStyles } from "src/types/emotion";

type AccordionPanelProps = React.ComponentPropsWithoutRef<"div"> & {
  index?: number;
} & MediaQueryStyles;

const AccordionPanel: FC<AccordionPanelProps> = (props) => {
  const { children, index, ...restProps } = props;
  const { activeIndex } = useAccordionContext();

  const isOpen = activeIndex === index;

  const style = restProps;
  const css = mq(style);

  return (
    <AccordionPanelStyles.Container
      style={{ display: isOpen ? "block" : "none" }}
      css={css}
    >
      {children}
    </AccordionPanelStyles.Container>
  );
};

export default AccordionPanel;
