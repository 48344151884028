import { FC } from "react";

const ChevronRight: FC<React.SVGAttributes<SVGElement>> = (props) => (
  <svg
    width="13"
    height="20"
    viewBox="0 0 13 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.51347 18.9935C0.712135 18.2981 0.706874 17.0559 1.50229 16.3537L8.69903 10L1.50739 3.68388C0.707665 2.98151 0.706051 1.73666 1.50396 1.03222C2.17188 0.442538 3.1744 0.442538 3.84232 1.03222L12.3018 8.5007C13.2035 9.29684 13.2035 10.7032 12.3018 11.4993L3.82424 18.9837C3.16524 19.5655 2.17737 19.5697 1.51347 18.9935Z"
      fill="#676767"
    />
  </svg>
);

export default ChevronRight;
