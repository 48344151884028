import React, { useState } from 'react'
import { Box, Heading, Text, UIProvider } from '../../UI'
import CalculatorResultSection from './CalculatorResultSection'
import CalculatorFormSection from './CalculatorFormSection'

const Calculator = () => {
  const [values, setValues] = useState({
    price: null,
    downPayment: null,
    installmentTerm: null,
    installment: null
  })

  return (
    <React.Fragment>
      <UIProvider>
        <Box maxWidth={['100%', '1040px']} margin="0 auto">
          <Heading
            fontFamily="Roboto"
            fontSize={['24px', '34px']}
            fontWeight={['700']}
            lineHeight={['28px', '64px']}
            letterSpacing={['-0.24px', '-0.5px']}
            textAlign="center"
            margin={['24px 0 0', '46px 0 0']}
          >
            Kalkulator Simulasi Pembiayaan
          </Heading>
          <Text
            fontFamily="Roboto"
            fontSize={['16px']}
            fontWeight={['400']}
            lineHeight={['24px']}
            textAlign="center"
            marginBottom={['24px']}
          >
            Belanja jadi lebih nyaman dengan cek jumlah cicilan per bulan terlebih dahulu.
          </Text>
          <Box
            maxWidth={['1064px']}
            width={['auto', '100%']}
            minHeight={['397px']}
            boxShadow={['0 2px 12px 0px #00000040']}
            borderRadius={['16px']}
            display={['flex']}
            flexDirection={['column', 'row']}
            margin={['0 16px', 'auto']}
          >
            <CalculatorFormSection setValues={setValues} />
            <CalculatorResultSection values={values} />
          </Box>
        </Box>
      </UIProvider>
    </React.Fragment>
  )
}

export default Calculator
