import React, { FC, useRef } from "react";
import * as RadioStyles from "./Radio.styles";

export type RadioProps = React.ComponentPropsWithoutRef<"input"> & {
  label?: string;
};

const Radio: FC<RadioProps> = (props) => {
  const { name, value, label = "", checked = false, ...restProps } = props;

  const ref = useRef<HTMLInputElement>(null);

  const handleContainerClick = () => {
    // Trigger the onChange event manually when the container is clicked
    ref.current?.click();
  };

  return (
    <RadioStyles.Container onClick={handleContainerClick}>
      <RadioStyles.RadioCustom>
        <RadioStyles.Radio
          {...restProps}
          ref={ref}
          type="radio"
          id={label}
          name={name}
          value={value}
          checked={checked}
        />
      </RadioStyles.RadioCustom>
      <RadioStyles.Label htmlFor={label}>{label}</RadioStyles.Label>
    </RadioStyles.Container>
  );
};

export default Radio;
