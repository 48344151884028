import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  cursor: pointer;

  & svg {
    width: 12px;
    height: 8px;
  }

  @media screen and (min-width: 480px) {
    & svg {
      width: 20px;
      height: 12px;
    }
  }
`;
