/** @jsxImportSource @emotion/react */

import React, { Children, FC, cloneElement } from "react";
import * as RadioGroupStyles from "./RadioGroup.styles";
import { RadioProps } from "../Radio/Radio";
import { MediaQueryStyles } from "src/types/emotion";
import { mq } from "src/core/emotion/breakpoints";

type RadioGroupProps = React.ComponentPropsWithoutRef<"div"> & {
  name?: string;
  label?: string;
  children: React.ReactElement<RadioProps>[];
  value?: unknown;
} & MediaQueryStyles;

const RadioGroup: FC<RadioGroupProps> = (props) => {
  const { children, name, label, value, ...restProps } = props;

  const hasLabel = !!label;

  const clonedChildren = Children.map(children, (child) =>
    cloneElement(child, { name, checked: child.props.value == value })
  );

  const style = restProps;
  const css = mq(style);

  return (
    <RadioGroupStyles.Container {...restProps}>
      {hasLabel && <RadioGroupStyles.Label>{label}</RadioGroupStyles.Label>}
      <RadioGroupStyles.Group css={css}>
        {clonedChildren}
      </RadioGroupStyles.Group>
    </RadioGroupStyles.Container>
  );
};

export default RadioGroup;
