import React from "react";
import Link from "next/link";
import propTypes from "prop-types";
import "lazysizes";
import PartnerBlock from "./PartnerBlock";

const HCPartners = ({ partner }) => {
  return (
    <div className="partner-wrapper">
      <div className="partner-container">
        <div className="partner-single-container">
          <div>
            <h2 className="partner-title">Beberapa Mitra Kami</h2>
            <p className="partner-sub-title">
              Belanja mudah dengan Home Credit di lokasi-lokasi ini!
            </p>
          </div>
          <PartnerBlock items={partner} />
          <Link href="/mitra" as="/mitra" legacyBehavior>
            <a>
              <h3 className="partner-showmore">Lihat Semua Mitra Kami</h3>
            </a>
          </Link>
        </div>
      </div>
      <style jsx>{`
        .partner-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .partner-container {
          width: 100%;
          max-width: 960px;
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .partner-single-container {
          padding: 46px 16px 46px 16px;
        }
        .partner-title {
          font-weight: bold;
          margin-bottom: 0;
          font-size: 34px;
          text-align: center;
        }
        .partner-sub-title {
          font-size: 21px;
          text-align: center;
        }
        .partner-showmore {
          font-weight: bold;
          margin-bottom: 0;
          color: red;
          font-size: 15px;
          text-align: right;
        }

        @media only screen and (min-width: 600px) {
          .partner-sub-title {
            margin-left: 3em;
            margin-right: 3em;
          }
        }
        @media only screen and (max-width: 600px) {
          .partner-sub-title {
            margin-left: 3em;
            margin-right: 3em;
          }
          .partner-title {
            font-size: 18px;
          }
          .partner-sub-title {
            font-size: 12px;
          }
          .partner-showmore {
            font-size: 12px;
          }
        }
      `}</style>
    </div>
  );
};

HCPartners.propTypes = {
  partner: propTypes.array
};

export default HCPartners;
