import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid #d8d8d8;

  &:first-child {
    border-top: 2px solid #d8d8d8;
  }
`;
