import { Box, Heading } from '../../UI'

type CalculatorSectionWrapperProps = React.ComponentPropsWithoutRef<'div'> & {
  title?: string
}

const CalculatorSectionWrapper: React.FC<CalculatorSectionWrapperProps> = props => {
  const { title, children, ...restProps } = props
  return (
    <Box padding={['24px']} {...restProps}>
      <Heading
        fontSize={['20px']}
        fontWeight={['700']}
        lineHeight={['24px']}
        letterSpacing={['-0.15']}
        textAlign={['center']}
        marginBottom={['16px']}
      >
        {title}
      </Heading>
      {children}
    </Box>
  )
}

export default CalculatorSectionWrapper
