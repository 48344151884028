import React, { FC, useEffect, useRef, useState } from "react";
import * as UploadFileStyles from "./UploadFile.styles";
import { Box } from "src/components/UI";
import { validateFileSize, validateFileType } from "./utils/file";
import dynamic from "next/dynamic";

import PreviewIcon from "../../../../public/static/images/png/mitra/preview.png";
import { PreviewImageProps } from "./PreviewImage";

// This required to be csr render because this component use ReactDOM.createPortal.
// when use ReactDOM.createPortal you need to use window.document.getElementById("preview") to get the element.
// window.document is not available in server side.
const PreviewImage = dynamic(
  () => import("./PreviewImage").then((mod) => mod.PreviewImage),
  {
    ssr: false,
  }
) as React.FC<PreviewImageProps>;

export type UploadFileProps = React.ComponentPropsWithoutRef<"input"> & {
  caption?: string;
  maxSize?: number;
  units?: "KB" | "MB";
  file: File;
  setFile: (file: File) => void;
};

const UploadFile: FC<UploadFileProps> = (props) => {
  const {
    caption = "Format JPG. Ukuran maksimal 2 MB.",
    maxSize = 2,
    units = "MB",
    file,
    setFile,
    onChange = () => {},
    ...restProps
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>("");
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    // Validate file size
    const isFileSizeValid = validateFileSize(file, maxSize, units);
    // Validate file type
    const isFileTypeValid = validateFileType(file, ["image/jpeg", "image/jpg"]);

    const isInvalid = Boolean(isFileSizeValid || isFileTypeValid);

    if (isInvalid) {
      setError(isFileSizeValid || isFileTypeValid);
      return;
    }

    // Generate a preview URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
    setFile(file);

    onChange(e);
  };

  const handlePlaceholderClick = () => {
    inputRef.current?.click();
  };

  const handlePreviewClick = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (file) {
      // Generate a preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  return (
    <UploadFileStyles.Container>
      <UploadFileStyles.Input
        ref={inputRef}
        type="file"
        onChange={handleUploadFile}
        {...restProps}
      />
      <Box>
        {file ? (
          <UploadFileStyles.Preview
            src={previewUrl}
            onClick={handlePreviewClick}
          />
        ) : (
          <UploadFileStyles.Placeholder
            onClick={handlePlaceholderClick}
            isError={Boolean(error)}
          >
            <img src={PreviewIcon} alt="preview-icon" />
            {error ? error : caption}
          </UploadFileStyles.Placeholder>
        )}
      </Box>
      <PreviewImage
        isOpen={isOpen}
        src={previewUrl}
        onEdit={handlePlaceholderClick}
        onClose={() => setIsOpen(false)}
      />
    </UploadFileStyles.Container>
  );
};

export default UploadFile;
