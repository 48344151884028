import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Checkbox = styled.input`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  position: relative;
  box-shadow: inset 0px 0px 0px 2px #a9a9a9;
  cursor: pointer;

  &:checked {
    background-color: ${({ theme }) => theme.colors.green};
    box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.colors.green};

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
`;

export const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-align: left;
  color: #404040;
  white-space: nowrap;
  cursor: pointer;
`;

const CheckIcon = (props) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.20711 7.04295C3.81658 7.43348 3.18342 7.43348 2.79289 7.04295L0.279119 4.52918C-0.0131054 4.23695 -0.0131053 3.76316 0.279119 3.47094C0.571055 3.179 1.04427 3.17867 1.33662 3.4702L3.5 5.62756L8.66 0.467562C8.95379 0.173773 9.43037 0.174615 9.72312 0.46944C10.0144 0.762795 10.0136 1.23649 9.72125 1.52881L4.20711 7.04295Z"
      fill="white"
    />
  </svg>
);

export const Check = styled(CheckIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
