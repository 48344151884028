import Layout from "../components/Layout";
import { defaultTitle, defaultDescription } from "../data/DataMetatags";
import NextHead from "../components/Seo/NextHead";
import HeroBanner from "../components/IndexPage/HeroBanner";
import WhyHC from "../components/IndexPage/WhyHC";
import Instagram from "../components/SocialMedia/InstagramFeed";
import HCPartners from "../components/IndexPage/HCPartners";
import BlogOverview from "../components/IndexPage/BlogOverview";
import ProductShowcase from "../components/IndexPage/ProductShowcase";
import HowToPay from "../components/IndexPage/HowToPay";
import { defBreadcrumb } from "../data/DataBreadcrumb";
import PaymentPartnerPartnerData from "../data/PaymentPartner/DataPaymentPartnerPartner";
import PaymentPartnerPaymentData from "../data/PaymentPartner/DataPaymentPartnerPayment";
import HandlingContract from "../components/Contract/HandlingContract";
import IAP from "../components/Contract/IAP";
import { getCookies } from "../utils/helpers";
import fetch from "../utils/fetch";
import { useEffect } from "react";
import Router from "next/router";
import { encrypt } from "../utils/cipher";
import { MonetizationBanner } from "../components/IndexPage/MonetizationBanner";
import Calculator from "../components/IndexPage/Calculator"

const Home = ({
  defaultTitle,
  defaultDescription,
  defBreadcrumb,
  codename,
  query,
  contractListData,
  approvedContractList,
  userName,
  redirectUrl
}) => {
  useEffect(() => {
    localStorage.setItem("userName", userName);
    localStorage.setItem("contract-list", JSON.stringify(contractListData));
    if (approvedContractList?.length > 1) {
      Router.push("/dashboard/action");
    } else if (approvedContractList?.length === 1) {
      window.location.replace(redirectUrl);
    }
  }, []);
  return (
    <Layout>
      <NextHead
        title={defaultTitle}
        description={defaultDescription}
        breadcrumb={defBreadcrumb}
        tracker={query}
      />
      <HeroBanner codename={codename} />
      <IAP
        ctaLink="/dashboard/action"
        infoCopy="Selesaikan Kewajiban Tertunda"
        rawDataContract={contractListData}
      />
      <HandlingContract rawDataContract={contractListData} />
      <WhyHC />
      <MonetizationBanner
        containerMargin={["16px 16px 0", "48px auto 0"]}
        titleTextAlign="center"
        titleFontSize={["20px", "34px"]}
        bannerRadius={["8px", "16px"]}
        gap={["16px", "24px"]}
      />
      <Calculator />
      <BlogOverview sectionTitle="Blog" />
      <HCPartners partner={PaymentPartnerPartnerData} />
      <Instagram hashtag="instagram_home" title="Testimonials" />
      <ProductShowcase
        codename="product_showcase_home"
        btnText="Lihat Selengkapnya"
      />
      <HowToPay payment={PaymentPartnerPaymentData} />
    </Layout>
  );
};

Home.getInitialProps = async ({ query, req, res }) => {
  const codename = "home";

  const token = getCookies("token", req);
  const rtoken = getCookies("rtoken", req);
  let contractListData = {};
  let approvedContractList = {};
  let userName = "";
  let redirectUrl = "";

  if (rtoken) {
    const invoiceUploadResponse = await fetch(
      "POST",
      "/application-service/get-user-detail",
      { authToken: token },
      true
    );
    const invoiceUploadData = invoiceUploadResponse?.data;
    userName = invoiceUploadData.phoneNo;
    const contractListResponse = await fetch(
      "POST",
      "/contract/get-all-contracts",
      { data: encrypt({ authToken: token, cuid: invoiceUploadData.cuid }) },
      true
    );

    contractListData = contractListResponse?.data;

    approvedContractList = contractListData?.contracts?.filter(
      (data) => data.status === "APPROVED"
    );
    if (res) {
      res.setHeader("Cache-Control", "no-store");
      if (
        contractListResponse?.status !== 200 ||
        contractListResponse?.data?.httpStatus !== 200
      ) {
        res?.writeHead(302, { location: "/authlogin?rtoken=" + rtoken });
        res?.end();
      }
      if (approvedContractList?.length === 1) {
        redirectUrl =
          approvedContractList[0].requestId && !approvedContractList[0].isMistake
            ? `${process.env.clientZoneAuth}${approvedContractList[0].requestId}`
            : `${process.env.credstoreAuth}${approvedContractList[0].contractNumber}`;
        res?.writeHead(302, {
          location: redirectUrl
        });
        res?.end();
      }
      if (rtoken) {
        res?.writeHead(302, { location: "/authlogin?rtoken=" + rtoken });
        res?.end();
      }
    }
  }

  return {
    defaultTitle,
    defaultDescription,
    defBreadcrumb,
    codename,
    query,
    contractListData,
    approvedContractList,
    userName,
    redirectUrl
  };
};

export default Home;
