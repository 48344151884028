import { ThemeProvider } from "@emotion/react";
import { theme } from "src/themes";
import { GlobalStyles } from "./GlobalStyles";

const UIProvider = (props) => {
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default UIProvider;
